export const dummyProducts = [
    {
        id : 1,
        heading : 'heading',
        description : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat deleniti at tempora praesentium, beatae neque ab vel perferendis maiores alias earum optio sequi saepe dolore illo, eum nemo! Commodi, fuga!',
        likes : 100,
        imagePath : '/Images/logo-placeholder.png',
        comments : [{
            comment_id:1,
            comment_text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi erat metus, fermentum id sagittis in, aliquet vel erat. In aliquet condimentum sapien at tempus. Pellentesque vel ante sed felis dictum lobortis non eget nunc. Morbi tincidunt non massa a ultricies. In pretium sapien sed leo varius rhoncus. Ut maximus ut sapien in dictum. Praesent pretium odio at dolor scelerisque, at iaculis odio posuere. Suspendisse faucibus, erat eget feugiat tincidunt, justo libero condimentum quam, vel rhoncus mauris ipsum non quam. Nam semper metus ac elit volutpat porta. Nunc finibus nec mauris sed rutrum. Sed commodo urna quis feugiat volutpat. Donec aliquet vitae arcu vitae bibendum. Quisque suscipit orci libero, vel sagittis lorem placerat in. Etiam laoreet quis lorem non bibendum. Nulla vitae lacus neque. Aliquam at nibh nibh. Donec vitae tristique diam. Praesent mollis auctor congue. Donec vulputate purus nec fringilla porttitor. Duis eleifend tristique neque, at euismod quam accumsan in. Curabitur gravida, purus et gravida scelerisque, sem est accumsan nisi, et porta nibh velit non sem. Vivamus lorem quam, lacinia sed massa at, convallis vulputate erat."
        }, 
        {
            comment_id:2,
            comment_text:'product 1, comment text 2'
        },
        {
            comment_id:3,
            comment_text:'product 1, comment text 3'
        }
    ]
    },
    {
        id : 2,
        heading : 'heading',
        description : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat deleniti at tempora praesentium, beatae neque ab vel perferendis maiores alias earum optio sequi saepe dolore illo, eum nemo! Commodi, fuga!',
        likes : 1710,
        imagePath : '/Images/logo-placeholder.png',
        comments : [{
            comment_id:1,
            comment_text:'product 2, comment text 1'
        }, 
        {
            comment_id:2,
            comment_text:'product 2, comment text 2'
        },
        {
            comment_id:3,
            comment_text:'product 2, comment text 3'
        }
    ]
    },
    {
        id : 3,
        heading : 'heading',
        description : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat deleniti at tempora praesentium, beatae neque ab vel perferendis maiores alias earum optio sequi saepe dolore illo, eum nemo! Commodi, fuga!',
        likes : 1710,
        imagePath : '/Images/logo-placeholder.png',
        comments : [{
            comment_id:1,
            comment_text:'product 3, comment text 1'
        }, 
        {
            comment_id:2,
            comment_text:'product 3, comment text 2'
        },
        {
            comment_id:3,
            comment_text:'product 3, comment text 3'
        }
    ]
    }
];